import React from 'react'
import PropTypes from 'prop-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

const RichTextRenderer = ({ node }) => {
  const data = JSON.parse(node)

  return documentToReactComponents(data)
}

RichTextRenderer.propTypes = {
  node: PropTypes.node.isRequired,
}

export default RichTextRenderer
